exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publikationen-js": () => import("./../../../src/pages/publikationen.js" /* webpackChunkName: "component---src-pages-publikationen-js" */),
  "component---src-pages-team-2014-js": () => import("./../../../src/pages/team/2014.js" /* webpackChunkName: "component---src-pages-team-2014-js" */),
  "component---src-pages-team-2015-js": () => import("./../../../src/pages/team/2015.js" /* webpackChunkName: "component---src-pages-team-2015-js" */),
  "component---src-pages-team-2016-js": () => import("./../../../src/pages/team/2016.js" /* webpackChunkName: "component---src-pages-team-2016-js" */),
  "component---src-pages-team-2017-js": () => import("./../../../src/pages/team/2017.js" /* webpackChunkName: "component---src-pages-team-2017-js" */),
  "component---src-pages-team-2019-js": () => import("./../../../src/pages/team/2019.js" /* webpackChunkName: "component---src-pages-team-2019-js" */),
  "component---src-pages-team-2024-js": () => import("./../../../src/pages/team/2024.js" /* webpackChunkName: "component---src-pages-team-2024-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

